.video-container {
  position: relative;
  margin: 100px auto;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(#091b32 5%, #091b3200 100%);
}
