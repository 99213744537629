.tittle {
  position: relative;
  text-align: center;
  font-size: 28px;
  font-weight: 300;
  margin: 70px 0 30px;
}
.tittle h2 {
  font-size: 35px;
  margin-top: 7px;
  color: #003973;
  font-weight: 600;
  text-transform: none;
}
.lines {
  position: relative;
}


.arrow {
  position: absolute;
  top: -15px;
  left: 50%;
  transform: translateX(-50%);
  width: 30px;
  height: auto;
  animation: moveArrow 4s linear infinite;
}

@keyframes moveArrow {
  0% {
    left: calc(50% - 10px);
  }
  50% {
    left: calc(50% + (2.5% - 10px));
  }
  100% {
    left: calc(50% - 10px);
  }
}
@media (max-width: 768px) {
  .tittle {
    font-size: 24px;
    margin: 50px 0 20px;
  }
  .lines::after {
    width: 10%;
  }

  .tittle h2 {
    font-size: 30px;
  }
  .arrow {
    width: 25px;
  }
}

@media (max-width: 576px) {
  .tittle {
    font-size: 20px;
    margin: 40px 0 10px;
  }

  .tittle h2 {
    font-size: 25px;
  }

  .arrow {
    width: 20px;
  }
}
