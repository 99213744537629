.products-container {
  background-image: url("../../assets/map-2.png");
  padding: 10px;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.card-product {
  width: 290px;
  background-color: #fff;
  margin: 20px;
  padding: 20px;
  border-radius: 8px;
}
.product-icon {
  font-size: 22px;
  width: 12%;
  background: #f5f7fa;
  color: rgb(0, 57, 115);
  padding: 5%;
  border-radius: 5px;
  margin-bottom: 5%;
}
.card-content-product h3 {
  text-transform: uppercase;
  line-height: 1.6;
  color: #121d50;
  font-size: 15px;
  margin-bottom: 5%;
}
.card-content-product p {
  font-size: 14px;
  text-align: justify;
  color: #5c6c7b;
  line-height: 1.5;
}
.card-product:hover {
  background-color: #121d50;
  color: #121d50;
  /* color: #fff; */
}
.card-product:hover .card-content-product h3,
.card-product:hover .card-content-product p {
  color: #fff;
}
.product-heading h4 {
  margin-bottom: 1%;
  font-size: 15px;
  color: #0e33cb;
}
.product-heading {
  font-size: 20px;
  margin-top: 3%;
  margin-bottom: 5%;
  letter-spacing: 1.5px;
  flex-direction: column;
  line-height: 1.4;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-heading h2 {
  color: #003973;
}
.card-product:hover {
  animation: shake 0.5s ease-in-out;
}
.prod-btn {
  background-color: #edf0f3;
  color: #121d50;
  box-shadow: none;
}
.btnArrow {
  margin-left: 4px;
  animation: moveRights 1.5s ease infinite;
}
@keyframes moveRights {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(10px);
  }
  100% {
    transform: translateX(0);
  }
}
@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  25% {
    transform: translate(-5px, 0);
  }
  50% {
    transform: translate(5px, 0);
  }
  75% {
    transform: translate(-5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

@media (max-width: 768px) {
  .products-container h2 {
    font-size: 16px;
  }
  .products-container h4 {
    margin-bottom: 5%;
  }
}
