.GroupageSerivce p {
  margin: 20px auto;
  text-align: justify;
  font-size: 18px;
  color: #5c6c7b;
  line-height: 2;
  padding-left: 5%;
  padding-right: 5%;
}
.GroupageSerivce {
  margin-top: 7%;
}

.image-div {
  margin-top: 7%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.img {
  max-width: 50%;
  max-height: 30%;
}

@media (max-width: 768px) {
  .GroupageSerivce p {
    font-size: 16px;
    padding-left: 10px;
    padding-right: 10px;
  }

  .img {
    max-width: 80%;
    max-height: auto;
  }
}
