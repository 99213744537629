.about-us-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  padding: 20px;
}
img {
  max-width: 100%;
  height: auto;
}
.image-container {
  z-index: -19;
}
.image-container .image-3 {
  margin-top: 40px;
  margin-left: 40px;
  overflow: hidden;
  border-radius: 5px;
  display: inline-block;
}
.image-container .image-4 {
  position: absolute;
  left: 240px;
  bottom: 150px;
  display: inline-block;
  border-radius: 5px;
  overflow: hidden;
}

.image-container .image-1 {
  left: -50px;
  z-index: -1;
  bottom: 200px;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.text-content {
  background-image: url("../../assets/icon-dots.webp");
  padding-left: 15%;
  padding-top: 18%;
}
.about-arrow-icon {
  background-color: orange;
  padding: 5px;
  font-size: 12px;
  border: transparent;
  color: #fff;
  width: 1em;
  height: auto;
  vertical-align: middle;
  margin: 0;
  border-radius: 50%;
}

.text-content span {
  margin-left: 4%;
}
.text-content h6 {
  font-size: 12px;
  margin-bottom: 3%;
}

.text-content h1 {
  color: #121d50;
  margin-bottom: 20px;
}

.text-content ul {
  list-style-type: disc;
  padding-left: 20px;
  color: #5c6c7b;
  margin-top: 40px;
  margin-bottom: 40px;
}

.text-content p {
  font-size: 16px;
  color: #5c6c7b;
  letter-spacing: 1.3px;
  text-align: justify;
  line-height: 1.8;
}
@media (max-width: 768px) {
  .about-us-container {
    grid-template-columns: 1fr;
    padding: 10px;
  }

  .text-content {
    padding-left: 10%;
    padding-top: 10%;
  }

  .image-container .image-3,
  .image-container .image-4,
  .image-container .image-1 {
    display: none;
  }

  .image-container .image-2 {
    margin: 0 auto;
  }

  .text-content ul {
    padding-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .text-content {
    padding-left: 5%;
    padding-top: 5%;
  }

  .text-content p {
    font-size: 14px;
  }

  .text-content h1 {
    font-size: 24px;
  }

  .about-arrow-icon {
    font-size: 10px;
    padding: 4px;
  }

  .text-content ul {
    padding-left: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .text-content span {
    margin-left: 2%;
  }

  .text-content h6 {
    font-size: 10px;
    margin-bottom: 2%;
  }
}
@media (max-width: 1024px) {
  .about-us-container {
    grid-template-columns: 1fr;
    padding: 20px;
  }
  .image-2 img {
    width: -webkit-fill-available;
  }

  .image-container .image-3,
  .image-container .image-4,
  .image-container .image-1 {
    display: none;
  }

  .text-content {
    padding-left: 10%;
    padding-top: 10%;
  }

  .text-content ul {
    padding-left: 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}
