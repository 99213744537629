footer {
  margin-top: 20%;
  width: 100%;
  background: #0b1f34;
  color: #fff;
  padding: 100px 0 30px;
  position: relative;
  cursor: pointer;
  z-index: 1;
}
.row {
  width: 85%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}
.col p {
  margin-top: 3%;
  text-align: justify;
}
.col {
  flex-basis: 33%;
  line-height: 1.7;
  padding: 10px;
}
.col:nth-child(2) {
  flex-basis: 35%;
  line-height: 1.7;
}
.col:nth-child(3) {
  flex-basis: 15%;
  line-height: 1.7;
}
.col h3 {
  width: fit-content;
  margin-bottom: 40px;
  position: relative;
}
.email-id {
  width: fit-content;
  border-bottom: 1px solid #718096;
  margin-right: 0;
  margin: 20px 0px;
}
.col img {
  width: 150px;
}
ul li {
  list-style: none;
  margin-bottom: 12px;
}
ul li a {
  text-decoration: none;
}

.social-icons .fab {
  width: 25px;
  height: 25px;
  padding: 2%;
  border-radius: 50px;
  text-align: center;
  line-height: 40px;
  font-size: 25px;
  background: #fff;
  margin: 6px;
  cursor: pointer;
}
.social-icons .fab.facebook {
  color: #1877f2;
}
.social-icons .fab.whatsapp {
  color: #128c7e;
}
.social-icons .fab.linkdin {
  color: #0077b5;
}
.social-icons .fab.twitter {
  color: #1da1f2;
}
.social-icons .fab.telegram {
  color: #0088cc;
}
.social-icons .fab:hover {
  transform: scale(1.2);
}
hr {
  width: 90%;
  border: 0;
  border-bottom: 1px solid #718096;
  margin: 20px auto;
}

.text {
  text-align: center;
}

.underline {
  margin-top: 5px;
  width: 40%;
  height: 2px;
  border-radius: 3px;
  position: absolute;
  left: 0;
  overflow: hidden;
}
.underline span {
  width: 100px;
  height: 100%;
  background: #fff;
  border-radius: 3px;
  position: absolute;
  top: 0;
}

/* Waves */

footer .Wave {
  position: absolute;
  top: -100px;
  color: #0b1f34;
  left: 0;
  width: 100%;
  height: 100px;
  background: url("../../assets/wave.png");
  background-size: 1000px 100px;
}

footer .Wave#wave1 {
  z-index: 1000;
  opacity: 1;
  bottom: 0;
  animation: animateWave 6s linear infinite;
}
footer .Wave#wave2 {
  z-index: 999;
  opacity: 0.5;
  bottom: 10px;
  animation: animateWave2 5s linear infinite;
}
footer .Wave#wave3 {
  z-index: 1000;
  opacity: 0.2;
  bottom: 15px;
  animation: animateWave 5s linear infinite;
}

footer .Wave#wave4 {
  z-index: 999;
  opacity: 0.7;
  bottom: 20px;
  animation: animateWave2 3s linear infinite;
}

@keyframes animateWave {
  0% {
    background-position-x: 1000px;
  }
  100% {
    background-position-x: 0px;
  }
}
@keyframes animateWave2 {
  0% {
    background-position-x: 0px;
  }
  100% {
    background-position-x: 1000px;
  }
}

@keyframes moving {
  0% {
    left: -20px;
  }
  100% {
    left: 100%;
  }
}
@media (max-width: 700px) {
  footer {
    bottom: unset;
  }

  .col {
    flex-basis: 100%;
    padding: 10px;
  }
  .col:nth-child(2),
  .col:nth-child(3) {
    flex-basis: 100%;
  }
}
