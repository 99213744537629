@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Inter", sans-serif;
}
a {
  text-decoration: none;
  color: inherit;
  line-height: 1;
  cursor: pointer;
}
.container {
  padding-left: 5%;
  padding-right: 5%;
}
.containers {
  padding-left: 5%;
  padding-right: 5%;
  background-color: #f7f7fa;
}
.btn {
  background: #5e37b5;
  color: #fff;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 4px;
  cursor: pointer;
  border: 0;
  outline: 0;
}
