.Home-img {
  display: flex;
  align-items: center;
  min-height: 120vh;
  object-fit: cover;
  animation: change 20s infinite ease-in-out;
  background: linear-gradient(107deg, #091b32 23%, #091b3200 100%),
    url("../../assets/back1.png");
  background-size: cover;
}
.Home-text {
  margin-top: 7%;
  display: inline-block;
  margin-right: 2%;
  font-size: 30px;
  color: #fff;
  text-align: left;
  max-width: 700px;
}
.Home-text p {
  font-family: "Cabin", sans-serif;
  color: #ffffff91;
  line-height: 1.7;
  margin-left: 8%;
  margin-top: 2%;
  font-size: 22px;
  margin-bottom: 7%;
}
.typing-text::after {
  content: ".";
  display: inline-block;
  width: 0;
  animation: cursor 1s step-end infinite;
}

@keyframes cursor {
  50% {
    opacity: 0;
  }
}
.Home-text h1 {
  margin-left: 8%;
  width: 100%;
  line-height: 1.4;
}
.Home-text p {
  width: 100%;
}

@keyframes change {
  0% {
    background-image: linear-gradient(107deg, #091b32 23%, #091b3200 100%),
      url("../../assets/back2.png");
  }
  15% {
    background-image: linear-gradient(107deg, #091b32 23%, #091b3200 100%),
      url("../../assets/back3.png");
  }
  25% {
    background-image: linear-gradient(107deg, #091b32 23%, #091b3200 100%),
      url("../../assets/back4.png");
  }
  35% {
    background-image: linear-gradient(107deg, #091b32 23%, #091b3200 100%),
      url("../../assets/back5.png");
  }
  45% {
    background-image: linear-gradient(107deg, #091b32 23%, #091b3200 100%),
      url("../../assets/back6.png");
  }
  55% {
    background-image: linear-gradient(107deg, #091b32 23%, #091b3200 100%),
      url("../../assets/back7.png");
  }
  65% {
    background-image: linear-gradient(107deg, #091b32 23%, #091b3200 100%),
      url("../../assets/back8.png");
  }
  75% {
    background-image: linear-gradient(107deg, #091b32 23%, #091b3200 100%),
      url("../../assets/back9.png");
  }
  85% {
    background-image: linear-gradient(107deg, #091b32 23%, #091b3200 100%),
      url("../../assets/back10.png");
  }
}

@media screen and (max-width: 768px) {
  .Home-text {
    font-size: 24px;
    max-width: 80%;
  }

  .Home-text p {
    font-size: 18px;
  }
}

@media screen and (max-width: 576px) {
  .Home-text {
    font-size: 10px;
    max-width: 89%;
  }

  .Home-text p {
    font-size: 16px;
  }
}
