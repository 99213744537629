.contact {
  margin: 80px auto;
  max-width: 90%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dark-btn {
  background-color: rgb(0, 57, 115);
  font-weight: 600;
  color: #fff;
  width: 30%;
  padding: 2%;
}
.dark-btn:hover {
  transform: scale(1.1);
}
.contact-col {
  flex-basis: 46%;
  color: #003973;
}
.contact-col h1 {
  font-weight: 700;
  font-size: 50px;
  margin-top: -12%;
}
.contact-col h3 {
  font-weight: 700;
  display: flex;
  margin-top: 1%;
  margin-right: 3%;
}
.contact-col h2 {
  font-weight: 700;
  margin-bottom: 5%;
  font-size: 30px;
}
.contact-col p {
  margin-top: 3%;
  font-family: "Cabin", sans-serif;
  line-height: 1.7;
  font-size: 17px;
  color: #003973;
  text-align: justify;
  margin-left: 2%;
}
.contact-col ul {
  position: relative;
  left: 6%;
}
.contact-col ul li {
  font-family: "Cabin", sans-serif;
  display: flex;
  align-items: center;
  margin: 35px 0;
}
.icons {
  padding: 5%;
  font-size: 20px;
  margin-right: 15px;
}
.contact form label {
  font-size: 15px;
  font-weight: 600;
}
.contact form input,
.contact form textarea {
  display: block;
  width: 100%;
  border-radius: 10px;
  background: #ececec;
  padding: 15px;
  border: 0;
  outline: 0;
  margin-top: 5px;
  margin-bottom: 25px;
  resize: none;
}

.input-group {
  display: flex;
  flex-wrap: wrap;
}

.input-row {
  flex: 0 0 50%;
  padding-right: 10px;
}

.input-row label {
  display: block;
  margin-bottom: 5px;
}

.input-row input {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  box-sizing: border-box;
  margin-bottom: 10px;
}

@media (max-width: 700px) {
  .contact {
    display: block;
  }
  .contact-col h1 {
    font-size: 30px;
  }
  .contact-col {
    padding: 20px;
  }
  .contact-col h3 {
    margin-right: 5%;
  }
}
