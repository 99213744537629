.body {
  display: grid;
  place-items: center;
  margin-bottom: 3%;
}

.slider {
  height: 350px;
  margin: auto;
  overflow: hidden;
  width: 90%;
  position: relative;
  place-items: center;
  display: grid;
}

.slide-track {
  display: flex;
  width: calc(350px * 18);
  animation: scroll 30s linear infinite;
}
.slide-track:hover {
  animation-play-state: paused;
}
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(calc(-350px * 9));
  }
}
.slide {
  height: 300px;
  width: 300px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
}
img {
  width: 100%;
  transition: transform 1s;
}
img:hover {
  transform: translateZ(20px);
}
