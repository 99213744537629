/* .card {
  border-radius: 8px;
  padding: 26px;
  margin: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  position: relative;
  overflow: hidden;
}

.hover-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0);
  opacity: 0;
  transition: -webkit-transform 0.4s ease;
  -webkit-transition: -webkit-transform 0.4s ease;
  transition: transform 0.4s ease;
  transition: transform 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.card:hover .hover-layer {
  opacity: 1;
}

.hover-layer::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 39px;
  height: 44px;
  background: url("../../assets/curve.jpg") no-repeat;
  z-index: 1;
}
.card h3,
.card h5,
.card p {
  transition: color 0.4s ease;
  position: relative;
  z-index: 2;
}

.card:hover h3,
.card:hover h5,
.card:hover p {
  color: #fff;
}
.card h3 {
  text-transform: uppercase;
  line-height: 1.6;
  color: #121d50;
  font-size: 15px;
  margin-bottom: 5%;
  position: relative;
  z-index: 2;
}

.card h5 {
  font-size: 14px;
  color: #121d50;
  margin-bottom: 5%;
  position: relative;
  z-index: 2;
}

.card p {
  font-size: 12px;
  text-align: justify;
  margin-top: 2.5%;
  color: #5c6c7b;
  line-height: 1.5;
  position: relative;
  z-index: 2;
}

.cardcontainer {
  margin: 100px auto;
  background: url("../../assets/contact-shape.png");
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  z-index: 999;
  justify-content: center;
} */

.card {
  border-radius: 8px;
  padding: 26px;
  margin: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 250px;
  position: relative;
  overflow: hidden;
}

.hover-layer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0);
  opacity: 0;
  transition: opacity 0.4s ease, transform 0.4s ease;
  -webkit-transition: opacity 0.4s ease, -webkit-transform 0.4s ease;
  -webkit-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transform-origin: left center;
  transform-origin: left center;
}

.card:hover .hover-layer {
  opacity: 1;
  -webkit-transform: scaleX(1);
  transform: scaleX(1);
}

.hover-layer::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 39px;
  height: 44px;
  background: url("../../assets/curve.jpg") no-repeat;
  z-index: 1;
}

.card h3,
.card h5,
.card p {
  transition: color 0.4s ease;
  position: relative;
  z-index: 2;
}

.card:hover h3,
.card:hover h5,
.card:hover p {
  color: #fff;
}

.card h3 {
  text-transform: uppercase;
  line-height: 1.6;
  color: #121d50;
  font-size: 15px;
  margin-bottom: 5%;
  position: relative;
  z-index: 2;
}

.card h5 {
  font-size: 14px;
  color: #121d50;
  margin-bottom: 5%;
  position: relative;
  z-index: 2;
}

.card p {
  font-size: 12px;
  text-align: justify;
  margin-top: 2.5%;
  color: #5c6c7b;
  line-height: 1.5;
  position: relative;
  z-index: 2;
}

.cardcontainer {
  margin: 30px auto;
  background: url("../../assets/contact-shape.png");
  gap: 20px;
  display: flex;
  flex-wrap: wrap;
  z-index: 999;
  margin-bottom: 6%;
  justify-content: center;
}

.top-content {
  text-align: center;
}

.OurService {
  display: inline-block;
  color: #ffdc39;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 2.4px;
  padding: 10px 20px 9px 20px;
  background-color: #14212a;
  border-radius: 50px 50px 50px 50px;
}
