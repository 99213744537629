@import url("https://fonts.googleapis.com/css2?family=Gluten:wght@100..900&family=Inter:wght@100..900&display=swap");

.dark-nav {
  background: #fff;
  transition: 0.5s;
}

nav {
  width: 100%;
  box-shadow: 0 2px 16px hsla(220, 32%, 8%, 0.3);
  background: #00224499;
  padding: 16px 0;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 10;
}

.logo {
  width: 160px;
}

nav ul li {
  color: #000;
  display: inline-block;
  list-style: none;
  font-weight: 500;
  margin: 5px 25px;
  font-size: 18px;
  position: relative;
}

nav ul li:hover,
nav ul li:focus {
  transform: scale(1.09);
}

nav ul li:hover {
  color: #003973;
}
.menu-icon {
  display: none;
}
@media (max-width: 895px) {
  nav {
    padding: 6px 0;
  }
  nav ul {
    position: fixed;
    top: 0;
    right: 0px;
    bottom: 0;
    background: #fff;
    z-index: -1;
    width: 200px;
    padding-top: 70px;
    transition: 0.5s;
  }
  nav ul li {
    display: block;
    color: #000;
    margin: 25px 40px;
  }
  nav .btn {
    padding: 5%;
  }

  .menu-icon {
    display: block;
    width: 40px;
  }
  .hide-mobile-menu {
    right: -200px;
  }
  .dark-nav {
    backdrop-filter: none;
  }
}
